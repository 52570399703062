const $dateFields = document.querySelectorAll(".date-field");

if ($dateFields.length) {
  $dateFields.forEach(function (dateField) {
    const websiteLang =
      document.documentElement.lang.trim().toLowerCase() || "fr";
    const localePaths = {
      fr: {
        ...require("vanillajs-datepicker/locales/fr").default[websiteLang],
        format: "dd/mm/yyyy",
      },
      en: {
        ...require("vanillajs-datepicker/locales/en-GB").default["en-GB"],
        format: "m/d/yy",
      },
      it: {
        ...require("vanillajs-datepicker/locales/it").default[websiteLang],
        format: "dd/mm/yy",
      },
      es: {
        ...require("vanillajs-datepicker/locales/es").default[websiteLang],
        format: "d/m/yy",
      },
      de: {
        ...require("vanillajs-datepicker/locales/de").default[websiteLang],
        format: "dd.mm.yy",
      },
    };
    const localeModule = localePaths[websiteLang];
    const dateFormatUI = dateField
      .closest(".date-field__wrapper")
      .querySelector(".date-value-ui");

    // add date format
    const dateFormat = dateField
      .closest(".form-group")
      .querySelector(".date-format");

    if (dateFormat) {
      dateFormat.innerHTML =
        websiteLang == "en" ? "m/d/yy" : localeModule.format;
    }

    import("vanillajs-datepicker").then(({ Datepicker }) => {
      Object.assign(Datepicker.locales, localeModule);
      const datepicker = new Datepicker(dateField, {
        autohide: true,
        format: localeModule.format,
        locale: localeModule,
        language: websiteLang,
      });

      // format date

      function formatDate() {
        const selectedDate = datepicker.getDate();

        if (selectedDate) {
          // Use Datepicker.formatDate() to format the selected date
          let formattedDate = Datepicker.formatDate(selectedDate, "mm/dd/yyyy");

          // Log the formatted date (you can use this wherever you need the date)
          return formattedDate;
        }
      }

      function formatLocalFormatDate(formattedDate) {
        // Manually fix the year if it's a two-digit year with "00"
        let yearPart = formattedDate.split("/")[2]; // Get the year part
        if (yearPart.length === 4 && yearPart.startsWith("00")) {
          yearPart = "20" + yearPart.slice(-2); // Replace "00" with "20" for the year
          formattedDate = formattedDate.replace(
            formattedDate.split("/")[2],
            yearPart
          );
          return formattedDate;
        }
        return formattedDate;
      }

      if (dateFormatUI) {
        dateField.setAttribute(
          "data-date-value",
          formatLocalFormatDate(formatDate())
        );
        dateFormatUI.innerHTML = formatLocalFormatDate(dateField.value);

        // // Listen for the changeDate event
        dateField.addEventListener("changeDate", function () {
          dateField.setAttribute(
            "data-date-value",
            formatLocalFormatDate(formatDate())
          );
          dateFormatUI.innerHTML = formatLocalFormatDate(dateField.value);
        });
      }
    });
  });
}
