const elementsWithDataToShow = document.querySelectorAll(".has-data-to-show");
const form = document.querySelector(".js-validate-form");
let newPristine = null;

function initPristine(form, Pristine) {
  if (newPristine) {
    newPristine.destroy();
  }
  newPristine = new Pristine(form);

  form.addEventListener("submit", function (e) {
    // check if the form is valid
    newPristine.validate();
    // add dynamic csrf toket
    const csrf_token = document.querySelector(
      "input[name*=_token][type=hidden]"
    );
    const dateInputs = document.querySelectorAll(".date-field");

    if (csrf_token) {
      const token = document.querySelector('meta[name="CSRF-Token"]')?.content;
      if (token) {
        csrf_token.setAttribute("value", token);
      }
    }

    if (dateInputs.length) {
      dateInputs.forEach(function (dateInput) {
        const dateValue = dateInput.dataset.dateValue;
        dateInput.value = dateValue;
      });
    }

    if (!newPristine.validate()) {
      e.preventDefault();
      console.log(newPristine);
      console.log(newPristine.getErrors());

      const hasDanger = document.querySelector(".has-danger");

      if (hasDanger) {
        window.scrollTo({
          top: document.querySelector(".has-danger").offsetTop,
          behavior: "smooth",
        });
      }
    }
  });
}

function initRecaptcha(form) {
  let recaptcha_script = form.querySelector('script[data-name="recaptcha"]');

  if (recaptcha_script) {
    let sc = document.createElement("script");
    let recaptcha_key = recaptcha_script.getAttribute("data-key");
    sc.setAttribute("src", recaptcha_script.getAttribute("src"));
    sc.setAttribute("type", "text/javascript");
    document.head.appendChild(sc);
    const formName = form.getAttribute("name");
    if (!formName) {
      console.error("Form does not have a name attribute.");
      return;
    }
    // Dynamically create the callback function using the form's name
    window[`recaptchaCallback_${formName}`] = function () {
      window.grecaptcha
        .execute(recaptcha_key, { action: "homepage" })
        .then(function (token) {
          const recaptchaInput = form.querySelector(`#${formName}`); // Dynamic input based on form name
          if (recaptchaInput) {
            recaptchaInput.value = token; // Set the token to the input
          } else {
            console.error(`No input found with id '${formName}' in the form.`);
          }
        });
    };
  }
}

function initCustomFileInput() {
  import("bs-custom-file-input").then((module) => {
    const bsCustomFileInput = module.default;
    bsCustomFileInput.init();
  });
}

function removeUploadedFile(wrapper) {
  const uploadeFileFields = wrapper.querySelectorAll(".custom-file-wrapper");

  if (uploadeFileFields.length) {
    uploadeFileFields.forEach(function (uploadeFileField) {
      const inputField = uploadeFileField.querySelector("input");
      const deleteFileBtn = uploadeFileField.querySelector(".icon-trash");
      const labelUploadFile = uploadeFileField.querySelector(
        ".custom-file-label .link"
      ).innerText;

      if (deleteFileBtn && inputField && labelUploadFile) {
        inputField.addEventListener("change", function () {
          if (inputField.value != "") {
            deleteFileBtn.classList.remove("d-none");
          } else {
            deleteFileBtn.classList.add("d-none");
          }
        });

        deleteFileBtn.addEventListener("click", function () {
          inputField.value = "";
          uploadeFileField.querySelector(".custom-file-label .link").innerHTML =
            labelUploadFile;
          deleteFileBtn.classList.add("d-none");
        });
      }
    });
  }
}

if (form) {
  import("pristinejs/dist/pristine").then((module) => {
    const Pristine = module.default;
    const selectHasSubField = form.querySelector(".hasSubField");

    initPristine(form, Pristine);

    elementsWithDataToShow.forEach(function (element) {
      const radioButtons = element.querySelectorAll("input[type='radio']");
      const checkboxButtons = element.querySelectorAll(
        "input[type='checkbox']"
      );
      const hiddenElementsIds = element.querySelectorAll("[data-to-show]");

      radioButtons.forEach(function (radioButton) {
        radioButton.addEventListener("click", function () {
          const targetId = radioButton.getAttribute("data-to-show");

          if (hiddenElementsIds.length) {
            hiddenElementsIds.forEach(function (hiddenElement) {
              const hiddenElementId =
                hiddenElement.getAttribute("data-to-show");

              if (document.getElementById(hiddenElementId)) {
                const requiredItem = document
                  .getElementById(hiddenElementId)
                  .querySelector(".is-required");

                document
                  .getElementById(hiddenElementId)
                  .classList.add("d-none");

                if (requiredItem) {
                  requiredItem.removeAttribute("required");
                }
              }
            });

            // Show the target element
            if (targetId) {
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                const allRequiredItems =
                  targetElement.querySelectorAll(".is-required");
                targetElement.classList.remove("d-none");
                if (allRequiredItems.length) {
                  allRequiredItems.forEach(function (requiredItem) {
                    requiredItem.setAttribute("required", "required");
                  });
                }
              }
            }

            initPristine(form, Pristine);
          }
        });
      });

      checkboxButtons.forEach(function (checkboxButton) {
        checkboxButton.addEventListener("click", function () {
          const targetId = checkboxButton.getAttribute("data-to-show");

          if (hiddenElementsIds.length) {
            hiddenElementsIds.forEach(function (hiddenElement) {
              const hiddenElementId =
                hiddenElement.getAttribute("data-to-show");

              if (document.getElementById(hiddenElementId)) {
                const targetElement = document.getElementById(targetId);
                const requiredItems = document
                  .getElementById(hiddenElementId)
                  .querySelectorAll(".is-required");
                const targetElementRequiredItems =
                  targetElement.querySelectorAll(".is-required");
                const targetElementDisplay =
                  targetElement.classList.contains("d-none");

                if (requiredItems) {
                  requiredItems.forEach(function (requiredItem) {
                    requiredItem.removeAttribute("required");
                  });
                }
                // Show the target element
                if (targetId) {
                  if (targetElementDisplay) {
                    targetElement.classList.remove("d-none");

                    if (targetElementRequiredItems) {
                      targetElementRequiredItems.forEach(
                        function (requiredItem) {
                          requiredItem.setAttribute("required", "required");
                        }
                      );
                    }
                  } else {
                    targetElement.classList.add("d-none");
                  }
                }
                initPristine(form, Pristine);
              }
            });
          }
        });
      });
    });

    // replace data to another element

    const dataToAddElement = document.querySelector("[data-to-add]");

    if (dataToAddElement) {
      const dataToAddId = dataToAddElement.getAttribute("data-to-add");
      const elementToReplace = document.getElementById(dataToAddId);

      if (elementToReplace) {
        const parentElement = dataToAddElement.parentNode;

        parentElement.appendChild(elementToReplace);
      }
    }

    const addFormDeleteLink = (item) => {
      const removeFormButton = item.querySelector(".btn-delete");

      if (removeFormButton) {
        removeFormButton.addEventListener("click", (e) => {
          const listElements = document.querySelectorAll("ul.materials li");
          const numberOfItems = listElements.length;

          e.preventDefault();
          // remove the li for the tag form
          if (numberOfItems > 1) {
            item.style.opacity = "0";
            setTimeout(function () {
              item.remove();
              initPristine(form, Pristine);
            }, 300);

            // item.remove();
          } else {
            // Reset the input fields within the li element
            const inputs = item.querySelectorAll("input");
            inputs.forEach(function (input) {
              input.value = "";
            });

            // Reset the file input field
            const fileInput = item.querySelector("input[type='file']");
            if (fileInput) {
              fileInput.value = "";
              // For modern browsers that support the "input" event, trigger it to clear the file input"s display
              const event = new Event("input", { bubbles: true });
              fileInput.dispatchEvent(event);
            }
          }

          const addItemLink = document.querySelector(".add_item_link");

          if (addItemLink) {
            if (numberOfItems < 6) {
              document.querySelector(
                ".add_item_link"
              ).parentElement.style.display = "inline-block";
            } else {
              document.querySelector(
                ".add_item_link"
              ).parentElement.style.display = "none";
            }
          }

          initPristine(form, Pristine);
        });
      }
    };

    document.querySelectorAll("ul.materials li").forEach((material) => {
      addFormDeleteLink(material);
    });

    document.querySelectorAll(".add_item_link").forEach((btnLink) => {
      btnLink.addEventListener("click", function (e) {
        const listElements = document.querySelectorAll("ul.materials li");
        const numberOfItems = listElements.length;
        const collectionHolder = document.querySelector(
          "." + e.currentTarget.dataset.collectionHolderClass
        );
        if (numberOfItems < 5 && numberOfItems > 0) {
          const item = document.createElement("li");

          item.innerHTML = collectionHolder.dataset.prototype.replace(
            /__name__/g,
            collectionHolder.dataset.index
          );

          collectionHolder.appendChild(item);
          addFormDeleteLink(item);
          removeUploadedFile(item);

          collectionHolder.dataset.index++;

          if (numberOfItems < 4) {
            btnLink.parentElement.style.display = "inline-block";
          } else {
            btnLink.parentElement.style.display = "none";
          }
        } else {
          btnLink.parentElement.style.display = "none";
        }

        initPristine(form, Pristine);
        initCustomFileInput();
      });
    });

    handleNumberInput();

    // close info card

    const infoCards = document.querySelectorAll(".picto-info-content");

    if (infoCards) {
      infoCards.forEach((infoCard) => {
        const closeIcon = infoCard.querySelector(".icon-close");
        const InfoContent = infoCard.querySelector(".picto-info-content-text");

        if (closeIcon && InfoContent) {
          closeIcon.addEventListener("click", function () {
            InfoContent.classList.remove("show");
          });
        }
      });
    }

    //bs custom file input

    initCustomFileInput();
    removeUploadedFile(form);

    // add new field when select a specifc option from select
    if (selectHasSubField) {
      const fieldToAdd = selectHasSubField.dataset.targetField;
      const selectedOption = selectHasSubField.dataset.option;

      if (fieldToAdd && selectedOption) {
        selectHasSubField.addEventListener("change", function () {
          const dataValue = this.options[this.selectedIndex].dataset.value;

          if (dataValue && dataValue == selectedOption) {
            form.querySelector(`#${fieldToAdd}`).classList.remove("d-none");
          } else {
            form.querySelector(`#${fieldToAdd}`).classList.add("d-none");
          }
        });
      }
    }
  });
}

// delete item
function deleteItem() {
  const deleteBtn = document.querySelectorAll(".delete-item-form");

  if (deleteBtn.length) {
    deleteBtn.forEach(function (item) {
      const itemToDeleteClass = item.dataset.itemToDelete;

      if (itemToDeleteClass) {
        const itemToDelete = item.closest(`.${itemToDeleteClass}`);

        item.addEventListener("click", function () {
          itemToDelete.remove();
        });
      }
    });
  }
}

// custom increment input

function handleNumberInput() {
  const incrementInput = document.querySelectorAll(".step-num-input");

  if (incrementInput.length) {
    incrementInput.forEach(function (item) {
      if (item.classList.contains("minus")) {
        item.addEventListener("click", function () {
          if (item.parentNode.querySelector("input[type=number]").value > 1) {
            item.parentNode.querySelector("input[type=number]").stepDown();
          }
        });
      }
      if (item.classList.contains("plus")) {
        item.addEventListener("click", function () {
          item.parentNode.querySelector("input[type=number]").stepUp();
        });
      }
    });
  }
}

// lazy Modal Form

const openLazyModals = document.querySelectorAll(".open-lazy-modal");

if (openLazyModals.length) {
  openLazyModals.forEach((openLazyModal) => {
    const TargetModal = document.querySelector(openLazyModal.dataset.bsTarget);

    if (TargetModal) {
      const modalContentUrl = TargetModal.dataset.contentUrl;
      const modalConfirmMessage = TargetModal.dataset.confirmMsgId;

      TargetModal.addEventListener("show.bs.modal", function () {
        fetch(modalContentUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text();
          })
          .then((data) => {
            const modalBody = TargetModal.querySelector(".modal-body");
            if (modalBody) {
              modalBody.innerHTML = data;
              const formElement = modalBody.querySelector("form");
              if (formElement) {
                initRecaptcha(formElement);
                import("pristinejs/dist/pristine")
                  .then((module) => {
                    const Pristine = module.default;
                    if (newPristine) {
                      newPristine.destroy();
                    }
                    newPristine = new Pristine(formElement);

                    // submit form without loading page
                    formElement.addEventListener("submit", function (e) {
                      e.preventDefault();

                      const formData = new FormData(formElement);
                      const requiredSections =
                        formElement.querySelector(".required-sections");

                      newPristine.validate();

                      if (newPristine.validate()) {
                        if (
                          requiredSections &&
                          requiredSections.childElementCount === 0
                        ) {
                          e.preventDefault();

                          const errorMessage =
                            requiredSections.dataset.msgError;

                          formElement.querySelector(
                            ".form-message-error"
                          ).innerHTML = errorMessage;
                          formElement
                            .querySelector(".form-message-error")
                            .classList.remove("d-none");

                          TargetModal.scrollTo({
                            top: TargetModal.querySelector(
                              ".form-message-error"
                            ).offsetTop,
                            behavior: "smooth",
                          });
                        } else {
                          fetch(formElement.action, {
                            method: "POST",
                            body: formData,
                            headers: {
                              "X-Requested-With": "XMLHttpRequest",
                            },
                          })
                            .then((response) => {
                              if (!response.ok) {
                                // Explicitly handle non-2xx status codes like 400
                                return response.json().then((data) => {
                                  throw new Error(
                                    data.message || "Error submitting the form."
                                  );
                                });
                              }
                              return response.json();
                            })
                            .then((data) => {
                              if (data.status === "success") {
                                import("bootstrap/js/dist/modal").then(
                                  (module) => {
                                    const Modal = module.default;
                                    const confirmationMsg =
                                      document.getElementById(
                                        modalConfirmMessage
                                      );
                                    const hideTargetModal =
                                      Modal.getInstance(TargetModal);
                                    const successModal = new Modal(
                                      confirmationMsg
                                    );

                                    hideTargetModal.hide();
                                    if (confirmationMsg) {
                                      successModal.show();
                                      // Start :  handle paid software download
                                      const downloadLink =
                                        confirmationMsg.querySelector(
                                          ".bloc-download-document-link"
                                        );
                                      if (data.download_url && downloadLink) {
                                        const downloadUrl = data.download_url;
                                        downloadLink.href = downloadUrl;
                                      }
                                      // End :  handle paid software download
                                    }
                                  }
                                );
                              } else {
                                // Handle validation errors from the backend
                                console.error(
                                  "Form submission error: ",
                                  data.message
                                );
                              }
                            })
                            .catch((error) => {
                              // Catch any errors from response or network issues
                              console.error(
                                "Submission failed: ",
                                error.message
                              );
                              const errorMsg = formElement.querySelector(
                                ".form-message-error"
                              );
                              if (errorMsg) {
                                initRecaptcha(formElement);
                                errorMsg.innerHTML =
                                  error.message || "An error occurred.";
                                errorMsg.classList.remove("d-none");

                                // Scroll to the error message for better UX
                                TargetModal.scrollTo({
                                  top: TargetModal.querySelector(
                                    ".form-message-error"
                                  ).offsetTop,
                                  behavior: "smooth",
                                });
                              }
                            });
                        }
                      } else {
                        e.preventDefault();
                        console.log(newPristine.validate());
                        console.log(newPristine);

                        const hasDanger = document.querySelector(".has-danger");

                        if (hasDanger) {
                          TargetModal.scrollTo({
                            top: modalBody.querySelector(".has-danger")
                              .offsetTop,
                            behavior: "smooth",
                          });
                        }
                      }
                    });

                    // Ajax call to remove items from storage

                    const itemsToDelete =
                      document.querySelectorAll(".delete-item-form");

                    if (itemsToDelete.length) {
                      itemsToDelete.forEach((button) => {
                        button.addEventListener("click", function () {
                          const itemId = button.dataset.itemId;
                          const storageUrl =
                            button.closest(".stored-data").dataset.storage;

                          if (itemId && storageUrl) {
                            fetch(storageUrl, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                              },
                              body: JSON.stringify({ productId: itemId }),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  console.log(
                                    "Item deleted successfully from storage"
                                  );
                                } else {
                                  console.error(
                                    "Failed to delete item from storage"
                                  );
                                }
                              })
                              .catch((error) => {
                                console.error("Error:", error);
                              });
                          }
                        });
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Pristine.js import error:", error);
                  });
              } else {
                console.error("No form element found in modal content.");
              }

              handleNumberInput();
              deleteItem();
            } else {
              console.error("Modal body element not found.");
            }
          })
          .catch((error) => {
            console.error("Fetch error:", error);
          });
      });
    }
  });
}

// search Input

const searchBlocks = document.querySelectorAll(".bloc-search-body");

if (searchBlocks.length) {
  searchBlocks.forEach(function (searchBlock) {
    const inputSearch = searchBlock.querySelector("input[type=text]");

    if (inputSearch) {
      inputSearch.addEventListener("input", () => {
        if (inputSearch.value.trim() === "") {
          inputSearch.classList.remove("filled");
        } else {
          inputSearch.classList.add("filled");
        }
      });
    }
  });
}

// show hide password

const togglePasswords = document.querySelectorAll(".toggle-password");

if (togglePasswords.length) {
  togglePasswords.forEach(function (togglePassword) {
    togglePassword.addEventListener("click", function () {
      const inputPassword = togglePassword
        .closest(".password-input")
        .querySelector("input");
      const inputType =
        inputPassword.getAttribute("type") === "password" ? "text" : "password";

      if (inputPassword) {
        inputPassword.setAttribute("type", inputType);
      }
    });
  });
}
