const form = document.querySelector(".block-form-builder form");

function removeUploadedFile(wrapper) {
  const uploadeFileFields = wrapper.querySelectorAll(".custom-file-wrapper");

  if (uploadeFileFields.length) {
    uploadeFileFields.forEach(function (uploadeFileField) {
      const inputField = uploadeFileField.querySelector("input");
      const deleteFileBtn = uploadeFileField.querySelector(".icon-trash");
      const labelUploadFile = uploadeFileField.querySelector(
        ".custom-file-label .link"
      ).innerText;

      if (deleteFileBtn && inputField && labelUploadFile) {
        inputField.addEventListener("change", function () {
          if (inputField.value != "") {
            deleteFileBtn.classList.remove("d-none");
          } else {
            deleteFileBtn.classList.add("d-none");
          }
        });

        deleteFileBtn.addEventListener("click", function () {
          inputField.value = "";
          uploadeFileField.querySelector(".custom-file-label .link").innerHTML =
            labelUploadFile;
          deleteFileBtn.classList.add("d-none");
        });
      }
    });
  }
}

if (form) {
  import("pristinejs/dist/pristine").then((module) => {
    const Pristine = module.default;
    const pristine = new Pristine(form);

    form.addEventListener("submit", function (e) {
      e.preventDefault();

      const dateInputs = document.querySelectorAll(".date-field");

      if (dateInputs.length) {
        dateInputs.forEach(function (dateInput) {
          const dateValue = dateInput.dataset.dateValue;
          dateInput.value = dateValue;
        });
      }
      // check if the form is valid
      pristine.validate();

      if (pristine.validate()) {
        form.submit();
      } else {
        const hasDanger = document.querySelector(".has-danger");

        if (hasDanger) {
          window.scrollTo({
            top: document.querySelector(".has-danger").offsetTop,
            behavior: "smooth",
          });
        }
      }
    });
  });

  //bs custom file input

  import("bs-custom-file-input").then((module) => {
    const bsCustomFileInput = module.default;
    bsCustomFileInput.init();
  });

  removeUploadedFile(form);
}
